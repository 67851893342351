<template>
  <div>
    <!--<el-radio v-model="postType" label="post">文章</el-radio>-->
    <!--<el-radio v-model="postType" label="product">产品</el-radio>-->
    <el-form ref="form" :inline="true" :model="form">
      <el-form-item :label="$t('type')">
        <el-select v-model="form.type" size="mini" @change="change" clearable>
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item[isEn + 'name']"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('button.page')" v-if="form.type == 'page'">
        <el-select v-model="form.url" size="mini" clearable>
          <el-option
              v-for="item in urlList"
              :key="item.path"
              :label="item.title"
              :value="item.path">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('category')" v-if="form.type == 'post'">
        <el-select v-model="form.postType" @change="change" size="mini">
          <el-option
              v-for="item in postTypes"
              :key="item.value"
              :label="item[isEn + 'name']"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('name')" v-if="form.type != 'page'">
        <el-input v-model="form.query" size="mini" clearable></el-input>
      </el-form-item>



      <el-form-item v-show="form.type != 'page'">
        <el-button type="primary" size="mini" @click="search">{{$t('search')}}</el-button>
      </el-form-item>

      <el-form-item v-show="form.type == 'page'">
        <el-button type="primary" size="mini" @click="ok">{{$t('button.ok')}}</el-button>
      </el-form-item>
    </el-form>



    <div v-show="form.type != 'page'">
      <el-row :gutter="20" type="flex" justify="start" class="flex-wrap" v-loading="loading">
        <el-col :span="6" v-for="(item, index) in list" :key="index" class="images-list line">
          <div @click="getSelect(item)" >

            <div v-if="item.type == 'corporateReport' || item.type == 'periodicReport'"
              style="margin-bottom: 6px;">
              <i class="el-icon-document" style="font-size: 30px;"></i>
              <span style="display: inline-block">.PDF</span>
            </div>

            <div v-if="!item.type">
              <el-image
                  v-if="item.images"
                  fit="cover"
                  :src="item.images.length ? item.images[0] : ''">
              </el-image>

              <el-image
                  v-if="item.banner"
                  fit="cover"
                  :src="item.banner">
              </el-image>
            </div>

            <el-image
                v-if="item.type == 'product' && item.images"
                fit="cover"
                :src="item.images.length ? item.images[0] : ''">
            </el-image>
            <el-image
                v-else-if="item.type == 'solution' && item.banner"
                fit="cover"
                :src="item.banner">
            </el-image>
            <el-image
                v-if="item.type == 'post' || item.type == 'researchFlow' || item.type == 'researchItem' || item.type == 'researchItem' || item.type == 'researchEquip' || item.type == 'mediaVideo' || item.type == 'investorEducation' || item.type == 'enterpriseDynamics' || item.type == 'industryDynamics' || item.type == 'mediaReport' || item.type == 'enterpriseImages' || item.type == 'activityImages'"
                fit="cover"
                :src="item.thumbnail">
            </el-image>



            <a class="images-list-title">{{item[isEn + 'title'] ? item[isEn + 'title'] : item.title}}</a>
            <el-checkbox v-if="multiple" v-model="item.checkbox"></el-checkbox>
          </div>
        </el-col>
      </el-row>

      <el-pagination
          class="mt20 line"
          background
          :total="total"
          :current-page.sync="query.page"
          :page-size="query.pageSize"
          @size-change="search"
          @current-change="search"
          layout="prev, pager, next">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import config from '@/config';
import {getPosts, getProduct, getSolution} from "@/api/os/admin";

export default{
  name: 'selectPost',
  props: {
    type: {
      type: String,
      default: 'post', // 文章 post、产品 product、解决方案 solution
    },
    multiple: {
      type: Boolean,
      default: false
    },
    attrs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: [
        {
          name: '文章',
          en_name: 'Posts',
          value: 'post'
        },
        {
          name: '产品',
          en_name: 'Product',
          value: 'product'
        },
        {
          name: '解决方案',
          en_name: 'Solution',
          value: 'solution'
        },
        {
          name: '页面',
          en_name: 'Page',
          value: 'page'
        }
      ],
      urlList: this.$t('menu'),
      postTypes: [
        {
          name: '研发流程',
          en_name: 'researchFlow',
          value: 'researchFlow'
        },
        {
          name: '研发设备',
          en_name: 'researchEquip',
          value: 'researchEquip'
        },
        {
          name: '研发团队',
          en_name: 'researchItem',
          value: 'researchItem'
        },
        {
          name: '企业动态',
          en_name: 'enterpriseDynamics',
          value: 'enterpriseDynamics'
        },
        {
          name: '行业动态',
          en_name: 'industryDynamics',
          value: 'industryDynamics'
        },
        {
          name: '媒体报道',
          en_name: 'mediaReport',
          value: 'mediaReport'
        },
        {
          name: '企业图片',
          en_name: 'enterprise Image',
          value: 'enterpriseImages'
        },
        {
          name: '活动图片',
          en_name: 'activity Image',
          value: 'activityImages'
        },
        {
          name: '视频资料',
          en_name: 'mediaVideo',
          value: 'mediaVideo'
        },
        {
          name: '公司公告',
          en_name: 'corporateReport',
          value: 'corporateReport'
        },
        {
          name: '定期报告',
          en_name: 'periodicReport',
          value: 'periodicReport'
        },
        {
          name: '投资者教育',
          en_name: 'InvestorEducation',
          value: 'investorEducation'
        }

        // {
        //   name: '技术支持',
        //   value: 'researchFlow'
        // },
        // {
        //   name: '投资者',
        //   value: 'investor'
        // },
        // {
        //   name: '媒体',
        //   value: 'media'
        // },
        // {
        //   name: '公司公告',
        //   en_name: 'corporateReport',
        //   value: 'corporateReport'
        // },
        // {
        //   name: '股价走势',
        //   en_name: 'stockPriceTrend',
        //   value: 'stockInfo'
        // },
      ],
      form: {
        type: this.type,
        postType: '',
        query: ''
      },


      list: [],
      total: 0,
      query: {
        page: 0,
        pageSize: config.default.pageLimit
      },
      loading: false
    }
  },
  created() {
    // if(this.type)
    this.search()
  },
  computed: {
    // add
    isEn() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    },
  },
  methods: {
    change() {
      this.query.page = 0
      this.search()
    },

    async search() {
      let data = await this.getList()
      this.list = data.list;
      this.total = data.total
    },
    getType() {

    },

    getList() {
      this.loading = true
      return new Promise( async resolve => {
        let data = {}
        if(this.form.type == 'post') {
          data = await getPosts({
            page: this.query.page,
            limit: this.query.pageSize,
            query: this.form.query,
            type: this.form.postType
          })
        }

        if(this.form.type == 'product') {
          data = await getProduct({
            page: this.query.page,
            pageSize: this.query.pageSize,
            query: this.form.query,
          })
        }

        if(this.form.type == 'solution') {
          data = await getSolution({
            page: this.query.page,
            limit: this.query.pageSize,
            query: this.form.query,
          })
        }
        this.loading = false
        resolve(data.data.data)
      })
    },

    getSelect(data) {
      if(data) {
        let attrs = this.attrs;
        let list = {}
        if(attrs && attrs.length > 0) {
          attrs.forEach(i => {
            list[i] = data[i]
          })
        } else {
          list = data;
        }

        switch (this.form.type) {
          case 'post':
            list.url = '/post/' + list.id
            break;
          case 'product':
            list.url = '/productDetail/' + list.id
            break;
          case 'solution':
            list.url = '/solutionDetail/' + list.id
            break;
          default:
              break;
        }
        list.selectType = this.form.type

        return this.$emit('select', list);
      }
    },
    ok() {
      return this.$emit('select', {
        url: this.form.url
      });
    }
  }
}
</script>

<style lang="less" scoped>
.images-list{
  margin-top: 20px;
  .images-list-title {
    display: block;
    overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //用省略号显示
    white-space:nowrap; //不换行
  }
}

</style>