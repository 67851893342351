<template>
  <el-card>
    <div slot="header">
      <h2>{{$t('page.console.menu.home_settings')}}</h2>
    </div>

    <el-form ref="form" :model="form" label-width="80px" label-position="left">
      <el-form-item :label="$t('lang')">
        <el-radio v-model="lang" label="zh-CN">中文</el-radio>
        <el-radio v-model="lang" label="en-US">English</el-radio>
      </el-form-item>

      <el-form-item :label="$t('page.console.menu.customer_service')">
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover">
          <el-image :src="form.customer_service"></el-image>
          <el-input slot="reference" v-model="form.customer_service" size="small" clearable>
            <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('customer_service')"></el-button>
          </el-input>
        </el-popover>
      </el-form-item>

      <el-form-item label="Banner">
        <el-table :data="form.banners.list" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="desc">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.desc" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_desc" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button
                    slot="append"
                    @click="selectView('banners', scope.$index)"
                    type="primary"
                    size="mini"
                    icon="el-icon-zoom-in">
                </el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="image">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover">
                <el-image :src="scope.row.image"></el-image>
                <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                  <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('banners', scope.$index)"></el-button>
                </el-input>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('banners', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('banners')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 解决方案 -->
      <el-form-item :label="$t('page.console.menu.solution')">

        <el-form-item :label="$t('page.console.menu.title')" class="w60">
          <el-input size="mini" v-if="lang == 'zh-CN'" v-model="form.solution.title"></el-input>
          <el-input size="mini" v-else v-model="form.solution.en_title"></el-input>
        </el-form-item>

        <el-form-item :label="$t('page.console.menu.desc')" class="w60 mt20">
          <el-input
              type="textarea"
              size="mini"
              :rows="2"
              v-if="lang == 'zh-CN'"
              v-model="form.solution.desc">
          </el-input>
          <el-input
              type="textarea"
              size="mini"
              :rows="2"
              v-else
              v-model="form.solution.en_desc">
          </el-input>
        </el-form-item>

        <el-table :data="form.solution.list" border class="mt20">
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button
                    slot="append"
                    @click="selectView('solution', scope.$index)"
                    type="primary"
                    size="mini"
                    icon="el-icon-zoom-in">
                </el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="image">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover">
                <el-image :src="scope.row.image"></el-image>
                <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                  <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('solution', scope.$index)"></el-button>
                </el-input>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('solution', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('solution')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 产品 -->
      <el-form-item :label="$t('page.console.menu.product')">
        <el-form-item :label="$t('page.console.menu.title')" class="w60">
          <el-input size="mini" v-if="lang == 'zh-CN'" v-model="form.product.title"></el-input>
          <el-input size="mini" v-else v-model="form.product.en_title"></el-input>
        </el-form-item>

        <el-form-item :label="$t('page.console.menu.desc')" class="w60 mt20">
          <el-input
              type="textarea"
              size="mini"
              :rows="2"
              v-if="lang == 'zh-CN'"
              v-model="form.product.desc">
          </el-input>
          <el-input
              type="textarea"
              size="mini"
              :rows="2"
              v-else
              v-model="form.product.en_desc">
          </el-input>
        </el-form-item>
        <el-table :data="form.product.list" class="mt20" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button
                    slot="append"
                    @click="selectView('product', scope.$index)"
                    type="primary"
                    size="mini"
                    icon="el-icon-zoom-in">
                </el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="image">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover">
                <el-image :src="scope.row.image"></el-image>
                <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                  <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('product', scope.$index)"></el-button>
                </el-input>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('product', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('product')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 动态 -->
      <el-form-item :label="$t('page.console.menu.dynamics')">
        <el-form-item :label="$t('page.console.menu.title')" class="w60">
          <el-input size="mini" v-if="lang == 'zh-CN'" v-model="form.dynamics.title"></el-input>
          <el-input size="mini" v-else v-model="form.dynamics.en_title"></el-input>
        </el-form-item>

        <el-form-item :label="$t('page.console.menu.desc')" class="w60 mt20">
          <el-input
              type="textarea"
              size="mini"
              :rows="2"
              v-if="lang == 'zh-CN'"
              v-model="form.dynamics.desc">
          </el-input>
          <el-input
              type="textarea"
              size="mini"
              :rows="2"
              v-else
              v-model="form.dynamics.en_desc">
          </el-input>
        </el-form-item>


        <el-table :data="form.dynamics.list" class="mt20" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="type">
            <template slot-scope="scope">
              <el-select v-model="scope.row.type" size="small" placeholder="请选择">
                <el-option
                    v-for="item in options.dynamics"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" v-show="scope.row.type != 'text'" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" v-show="scope.row.type != 'text'" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="desc">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.desc" v-show="scope.row.type != 'text'" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_desc" v-show="scope.row.type != 'text'" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url"  v-show="scope.row.type != 'text'" size="small" clearable>
                <el-button
                    slot="append"
                    @click="selectView('dynamics', scope.$index)"
                    type="primary"
                    size="mini"
                    icon="el-icon-zoom-in">
                </el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="image/text">
            <template slot-scope="scope">
              <div v-show="scope.row.type != 'text'">
                <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover">
                  <el-image :src="scope.row.image"></el-image>
                  <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                    <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('dynamics',scope.$index)"></el-button>
                  </el-input>
                </el-popover>
              </div>

              <div v-show="scope.row.type == 'text'">
                <el-button size="mini" @click="showDynamics(scope.row.list, scope.$index)">{{$t('button.add')}}</el-button>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('dynamics', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('dynamics')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 活动图集 -->
      <el-form-item :label="$t('page.console.menu.activity_images')">
        <el-form-item :label="$t('page.console.menu.title')" class="w60">
          <el-input size="mini" v-if="lang == 'zh-CN'" v-model="form.activityImages.title"></el-input>
          <el-input size="mini" v-else v-model="form.activityImages.en_title"></el-input>
        </el-form-item>

        <el-form-item :label="$t('page.console.menu.desc')" class="w60 mt20">
          <el-input
              type="textarea"
              size="mini"
              :rows="2"
              v-if="lang == 'zh-CN'"
              v-model="form.activityImages.desc">
          </el-input>
          <el-input
              type="textarea"
              size="mini"
              :rows="2"
              v-else
              v-model="form.activityImages.en_desc">
          </el-input>
        </el-form-item>
        <el-table :data="form.activityImages.list" class="mt20" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="url">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url" size="small" clearable>
                <el-button
                    slot="append"
                    @click="selectView('activityImages', scope.$index)"
                    type="primary"
                    size="mini"
                    icon="el-icon-zoom-in">
                </el-button>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="image">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover">
                <el-image :src="scope.row.image"></el-image>
                <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                  <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('activityImages', scope.$index)"></el-button>
                </el-input>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('activityImages', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('activityImages')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <!-- 底部 -->

      <el-form-item>
        <el-button class="right mt20" size="mini" type="primary" @click="onSave">{{$t('button.save')}}</el-button>
      </el-form-item>
    </el-form>


    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :multiple="false"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>

    <el-dialog width="70%" :visible.sync="dynamicsDialogVisible" :title="$t('tips.select')">
      <el-table :data="dynamics" class="mt20" border>
        <el-table-column type="index" label="#"></el-table-column>

        <el-table-column label="title">
          <template slot-scope="scope">
            <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
            <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
          </template>
        </el-table-column>

        <el-table-column label="desc">
          <template slot-scope="scope">
            <el-input v-if="lang == 'zh-CN'" v-model="scope.row.desc" size="small" clearable></el-input>
            <el-input v-else v-model="scope.row.en_desc" size="small" clearable></el-input>
          </template>
        </el-table-column>

        <el-table-column label="url">
          <template slot-scope="scope">
            <el-input v-model="scope.row.url" size="small" clearable>
              <el-button
                  slot="append"
                  @click="selectView('dynamicsInfo', scope.$index)"
                  type="primary"
                  size="mini"
                  icon="el-icon-zoom-in">
              </el-button>
            </el-input>
          </template>
        </el-table-column>

        <el-table-column label="image">
          <template slot-scope="scope">
            <el-popover
                placement="top-start"
                width="200"
                trigger="hover">
              <el-image :src="scope.row.image"></el-image>
              <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
                <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('dynamicsText',scope.$index)"></el-button>
              </el-input>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column :label="$t('action')">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="remove('dynamicsText', scope.$index)">{{$t('button.del')}}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addDynamicsAttrs">{{$t('button.add')}}</el-button>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dynamicsDialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="onSaveDynamics">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="selectPostDialogVisible" :title="$t('tips.select')">
      <select-post @select="select"></select-post>
    </el-dialog>
  </el-card>
</template>

<script>
import selectImage from '@/components/Images';
import selectPost from '@/view/os/console/SelectPost';
import {updateOption, getOption} from '@/api/os/admin';

export default {
  data() {
    return {
      lang: 'zh-CN',
      dialogVisible: false,
      form: {
        banners: {
          list: [],
          title: '',
          desc: ''
        },
        solution: {
          list: [],
          title: '',
          desc: ''
        },
        product: {
          list: [],
          title: '',
          desc: ''
        },
        dynamics: {
          list: [],
          title: '',
          desc: ''
        },
        activityImages: {
          list: [],
          title: '',
          desc: ''
        }
      },
      index: 0,
      type: '',
      selectPostDialogVisible: false,
      selectMeta: {
        name: '',
        index: 0
      },
      options: {
        dynamics: [
          /*{
            label: '图片展示',
            value: 'image'
          },*/
          {
            label: '幻灯片',
            value: 'text'
          },
          {
            label: '卡片',
            value: 'card'
          }
        ]
      },
      dynamicsDialogVisible: false,
      dynamicsIndex: 0,
      dynamics: []
    }
  },
  components: {
    selectImage,
    selectPost
  },
  created() {
    this.get()
  },
  methods: {
    addAttrs(type) {
      if(!this.form[type]) {
        let data = {
          list: [],
          title: '',
          desc: ''
        }

        this.$set(this.form, type, data);
      }

      return this.form[type].list.push({})
    },
    addDynamicsAttrs() {
      this.dynamics.push({
        title: '',
        desc: '',
        url: ''
      })
    },
    showDynamics(data = [], index) {
      this.dynamics = data || []
      this.dynamicsIndex = index
      this.dynamicsDialogVisible = true
    },
    onSaveDynamics() {
      this.$set(this.form.dynamics.list[this.dynamicsIndex], 'list', this.dynamics);
      this.dynamicsDialogVisible = false
    },
    remove(type, index) {
      if(type == 'dynamicsText') {
        return this.dynamics.splice(index, 1);
      }

      this.form[type].list.splice(index, 1)
    },
    mediaUpload(type,index) {
      this.dialogVisible = true
      this.index = index
      this.type = type
    },
    getImages(data) {
      this.dialogVisible = false
      if(this.type == 'customer_service') {
        return this.form[this.type] = data
      }
      if(this.type == 'dynamicsText') {
        return this.$set(this.dynamics[this.index], 'image', data);
      }
      this.$set(this.form[this.type].list[this.index], 'image', data)
      // this.form.banner[this.index].image = data
    },
    get() {
      getOption({
        name: 'page-home',
      }).then(res => {
        if(res.data.data) {
          this.form = res.data.data
        }
      })
    },
    onSave() {
      updateOption({
        name: 'page-home',
        value: this.form
      }).then(res => {
        this.$message.success(this.$t('tips.success'))
      })
    },
    selectView(name, index) {
      this.selectMeta.name = name
      this.selectMeta.index = index
      this.selectPostDialogVisible = true
    },
    select(data) {
      let url = data.url
      if(this.selectMeta.name == 'dynamicsInfo') {
        this.$set(this.dynamics[this.selectMeta.index], 'url', url);
      } else {
        this.$set(this.form[this.selectMeta.name].list[this.selectMeta.index], 'url', url)
      }
      this.selectPostDialogVisible = false
    }
  }
}
</script>